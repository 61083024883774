<template>
  <div>
    <el-container>
      <el-container>
        <!-- 中间内容 -->
        <el-main>
          <h2 class="depTitle">公告管理</h2>
          <div class="RightDiv">
            <div class="firDiv">
              <el-select v-model="noticeType" clearable placeholder="请选择公告类型" @change="statusChange(noticeType)">
                <el-option v-for="item in getTypeOptions" :key="item.id" :label="item.typeName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="firDiv">
              <el-button type="primary" @click="addModule">新增公告</el-button>
            </div>
          </div>
          <el-table :data="tableData" border center>
            <el-table-column type="index" label="序号" width="55" align="center">
            </el-table-column>
            <el-table-column prop="title" label="公告标题" align="center" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="type" label="公告类型" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 0">通知</span>
                <span  v-else-if="scope.row.type == 1">邀请</span>
              </template>
            </el-table-column>
            <el-table-column prop="describe" label="简述" align="center" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center" width="300">
              <template style="display: flex" slot-scope="scope">
                <el-button size="mini" type="primary" @click="updateNoticeManage(scope.row)">修改</el-button>
                <el-button size="mini" type="primary" @click="pushNoticeMessage(scope.row)">推送</el-button>
                <el-button size="mini" type="primary" @click="sendSmSNoticeMessage(scope.row)">发送</el-button>
                <el-button size="mini" type="danger" @click="deleteNoticeManage(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize"
                         layout="prev, pager, next" :total="total">
          </el-pagination>

          <el-dialog :title="winName" :visible.sync="revsuggdialogVisible" top="5px" width="1000px"
                     :before-close="revsuggdialogVisibleClose">
            <el-form :model="AddDateModel" ref="AddDateModel" :rules="AddModuleRules" label-width="100px">
              <el-form-item label="公告标题：" prop="title" class="lognClass">
                <el-input v-model="AddDateModel.title" placeholder="请填写公告标题"></el-input>
              </el-form-item>
              <el-form-item label="公告类型：" prop="type" class="lognClass">
                <el-radio-group v-model="AddDateModel.type">
                  <el-radio label="0">通知</el-radio>
                  <el-radio label="1">邀请</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="公告简述：" prop="describe" class="lognClass">
                <el-input v-model="AddDateModel.describe" placeholder="请填写公告简述"></el-input>
              </el-form-item>
              <el-form-item label="文件：" prop="describe" class="lognClass">
                <el-upload
                    class="pop-upload"
                    ref="upload"
                    action
                    :file-list="fileList"
                    :auto-upload="false"
                    :multiple="true"
                    :on-change="handleChange"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                >
                  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="公告内容：" prop="body">
                <div style="border: 1px solid #ccc;" v-if="revsuggdialogVisible">
                  <Toolbar
                      style="border-bottom: 1px solid #ccc"
                      :editor="editor"
                      :defaultConfig="toolbarConfig"
                      :mode="mode"
                  />
                  <Editor v-if="revsuggdialogVisible"
                          style="height: 500px; overflow-y: hidden;"
                          v-model="html"
                          :defaultConfig="editorConfig"
                          :mode="mode"
                          @onCreated="onCreated"
                  />
                </div>
              </el-form-item>
              <el-form-item class="depar-dia btnCen formBotton widBomtn">
                <el-button v-if="addModuleClick" type="primary" :loading="addModuleClickKing"
                           @click="saveNoticeMessage">保存
                </el-button>
                <el-button v-else type="info" :loading="addModuleClickKing"></el-button>
              </el-form-item>
            </el-form>
          </el-dialog>

          <el-dialog title="推送公告" :visible.sync="pushRevsuggdialogVisible" width="900px"
                     :before-close="persondialogVisibleClose" top="1vh">
            <div class="RightDiv">
              <div class="firDiv">
                <el-input v-model="attenName" clearable placeholder="请输入姓名" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
              </div>
              <div class="firDiv">
                <el-input v-model="attenPhone" clearable placeholder="请输入手机号" @keyup.enter.native="searchName" @blur="searchName" @clear="searchName"></el-input>
              </div>
              <div class="firDiv">
                <el-select v-model="attTypeVal" clearable placeholder="请选择参会人员类型" @change="typeChange(attTypeVal)">
                  <el-option v-for="item in participateTypeOptions" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="firDiv">
                <el-select v-model="pushStatus" clearable placeholder="请选择推送状态" @change="pushStatusChange(pushStatus)">
                  <el-option v-for="item in pushOptions" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-table ref="multipleTable" :data="pertableData" border center :row-key="res=>res.id" @selection-change="handleSelectionChange">

              <el-table-column type="selection" :reserve-selection="true" width="55" align="center">
              </el-table-column>
              <el-table-column prop="name" label="姓名" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center">
              </el-table-column>
              <el-table-column prop="workUnit" label="单位" align="center">
              </el-table-column>
              <el-table-column prop="duties" label="职务" align="center">
              </el-table-column>
              <el-table-column prop="participateType" label="参会人员类型" align="center">
              </el-table-column>
              <el-table-column prop="choose" label="推送状态" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.choose=='0'">未推送</div>
                  <div v-else-if="scope.row.choose=='1'">已推送</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background @current-change="handperleCurrentChange" :current-page="pageperNum"
                           :page-size="pageperSize" layout="prev, pager, next" :total="pertotal">
            </el-pagination>

            <div class="btnDiv">
              <el-button type="primary" @click="pushNotice()">推送</el-button>
            </div>
          </el-dialog>

          <el-dialog title="短信通知" :visible.sync="sendSmSRevsuggdialogVisible" width="900px"
                     :before-close="sendPersondialogVisibleClose" top="1vh">

            <div class="RightDiv">
              <div class="firDiv">
                <el-input v-model="attenName" clearable placeholder="请输入姓名" @keyup.enter.native="sendSearchName" @blur="sendSearchName" @clear="sendSearchName"></el-input>
              </div>
              <div class="firDiv">
                <el-input v-model="attenPhone" clearable placeholder="请输入手机号" @keyup.enter.native="sendSearchName" @blur="sendSearchName" @clear="sendSearchName"></el-input>
              </div>
              <div class="firDiv">
                <el-select v-model="attTypeVal" clearable placeholder="请选择参会人员类型" @change="sendTypeChange(attTypeVal)">
                  <el-option v-for="item in participateTypeOptions" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="firDiv">
                <el-select v-model="sendStatus" clearable placeholder="请选择发送状态" @change="sendStatusChange(sendStatus)">
                  <el-option v-for="item in sendOptions" :key="item.id" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-table ref="multipleTable" :data="sendTableData" border center :row-key="res=>res.id" @selection-change="sendHandleSelectionChange">


              <el-table-column type="selection" :reserve-selection="true" width="55" align="center">
              </el-table-column>
              <el-table-column prop="name" label="姓名" align="center">
              </el-table-column>
              <el-table-column prop="phone" label="手机号" align="center">
              </el-table-column>
              <el-table-column prop="workUnit" label="单位" align="center">
              </el-table-column>
              <el-table-column prop="duties" label="职务" align="center">
              </el-table-column>
              <el-table-column prop="participateType" label="参会人员类型" align="center">
              </el-table-column>
              <el-table-column prop="sendStatus" label="发送状态" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.sendStatus=='0'">未发送</div>
                  <div v-else-if="scope.row.sendStatus=='1'">已发送</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination background @current-change="sendCurrentChange" :current-page="pageperNum"
                           :page-size="pageperSize" layout="prev, pager, next" :total="pertotal">
            </el-pagination>

            <div class="btnDiv">
              <el-button type="primary" @click="sendNotice()">发送</el-button>
            </div>
          </el-dialog>


        </el-main>
      </el-container>
    </el-container>

  </div>

</template>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<script>
import _qs from "qs";
import Vue from 'vue'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default Vue.extend({
  components: {Editor, Toolbar},
  data() {
    return {
      attenName:'',
      attenPhone:'',
      attTypeVal:'',
      pushStatus:0,
      sendStatus:0,
      participateTypeOptions:[],
      pushOptions:[{"id": 0, "name": "未推送"}, {"id": 1, "name": "已推送"}],
      sendOptions:[{"id": 0, "name": "未发送"}, {"id": 1, "name": "已发送"}],


      fileList: [],
      noticeFileList: [],
      AddModuleRules: {
        title: [
          {required: true, message: "请填写公告标题", trigger: "blur"},
        ],
        type: [
          {required: true, message: "请选择公告类型", trigger: "blur"},
        ]
      },
      // open:false,
      winName: '',
      noticeType: '',
      addModuleClick: true,
      addModuleClickKing: false,
      // 当前页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      // 总数
      total: 1,

      // 推送弹窗分页参数
      pertotal:1,
      pageperNum: 1,
      pageperSize: 10,

      revsuggdialogVisible: false,
      pushRevsuggdialogVisible: false,
      sendSmSRevsuggdialogVisible: false,
      tableData: [],
      pertableData: [],
      sendTableData: [],
      AddDateModel: {},
      multipleSelection: [],
      sendMultipleSelection: [],
      pushStatusList: [],
      sendStatusList: [],
      editor: null,
      html: '',
      files: '',
      describe: '',
      noticeId: '',
      getTypeOptions: [{"id": 0, "typeName": "通知"}, {"id": 1, "typeName": "邀请"}],
      toolbarConfig: {},
      editorConfig: {
        placeholder: '请输入内容...',
        // autoFocus: false,
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 图片上传
          uploadImage: {
            server: Vue.prototype.GLOBAL + '/api/menuManage/uploadPosters',
            fieldName: 'modulePhoto',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 10 * 1024 * 1024, // 10M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['image/*'],
            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: ,
              // otherKey: 'yyy'
              // file:''
            },
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              Authorization: window.sessionStorage.getItem("token"),
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 10 秒
            timeout: 10 * 1000, //10 秒

            // 上传前
            onBeforeUpload(files) {
              // Message({
              //   message: '图片正在上传中,请耐心等待',
              //   duration: 0,
              //   customClass: 'uploadTip',
              //   iconClass: 'el-icon-loading',
              //   showClose: true
              // });
              return files;
            },
            // 自定义插入图片
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              // if (res.code === 200) {
              //   Message.success({
              //     message: `${res.data.originalName} 上传成功`
              //   });
              // } else {
              //   Message.error({
              //     message: `${res.data.originalName} 上传失败，请重新尝试`
              //   });
              // }
              insertFn(Vue.prototype.GLOBAL + res.data);
            },

            // 单个文件上传成功之后
            onSuccess(file, res) {
              console.log(`${file.name} 上传成功`, res);
            },

            // 单个文件上传失败
            onFailed(file, res) {
              console.log(`${file.name} 上传失败`, res);
            },

            // 上传进度的回调函数
            onProgress(progress) {
              console.log('progress', progress);
              // progress 是 0-100 的数字
            },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
            }
          },
          // 视频上传
          uploadVideo: {
            fieldName: 'file',
            server: '/api/conferManage/uploadPosters',

            // 单个文件的最大体积限制，默认为 10M
            maxFileSize: 50 * 1024 * 1024, // 50M

            // 最多可上传几个文件，默认为 5
            maxNumberOfFiles: 3,
            // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ['video/*'],

            // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
            meta: {
              // token: 'xxx',
              // otherKey: 'yyy'
            },

            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,

            // 自定义增加 http  header
            headers: {
              // Accept: 'text/x-json',
              // otherKey: 'xxx'
            },

            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,

            // 超时时间，默认为 30 秒
            timeout: 1000 * 1000, // 1000 秒,
            // 上传之前触发
            onBeforeUpload(file) {
              // Message({
              //   message: '视频正在上传中,请耐心等待',
              //   duration: 0,
              //   customClass: 'uploadTip',
              //   iconClass: 'el-icon-loading',
              //   showClose: true
              // });
              return file;
            },
            // 自定义插入视频
            customInsert(res, insertFn) {
              // 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
              // 先关闭等待的Message
              // Message.closeAll();
              // if (res.code === 200) {
              //   Message.success({
              //     message: `${res.data.originalName} 上传成功`
              //   });
              // } else {
              //   Message.error({
              //     message: `${res.data.originalName} 上传失败，请重新尝试`
              //   });
              // }
              insertFn(res.data.link, res.data.link);
            },
            // 上传进度的回调函数
            onProgress(progress) {
              console.log(progress);
              // onProgress(progress) {       // JS 语法
              // progress 是 0-100 的数字
            },

            // // 单个文件上传成功之后
            // onSuccess(file, res) {
            //   console.log(`${file.name} 上传成功`, res);
            //   this.successMsg(file);
            // },

            // // 单个文件上传失败
            // onFailed(file, res) {
            //   console.log(`${file.name} 上传失败`, res);
            //   this.errorMsg(file);
            // },

            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
              console.log(`${file.name} 上传出错`, err, res);
              Notification.error({
                title: '错误',
                message: `${file.name} 上传失败，请重新尝试`
              });
            }
          }
        }
      },
      mode: 'default', // or 'simple'

    }
  },
  methods: {
    // 勾选
    handleSelectionChange(val) {
      if(val.length>0){
        this.multipleSelection=[];
        this.pushStatusList=[];
        for(let i=0;i<val.length;i++){
          this.multipleSelection.push(val[i].id);
          this.pushStatusList.push(val[i].choose);
        }
      }else{
        this.multipleSelection = [];
        this.pushStatusList=[];
      }
    },
    // 短信发送勾选
    sendHandleSelectionChange(val) {
      if(val.length>0){
        this.sendMultipleSelection=[];
        this.sendStatusList=[];
        for(let i=0;i<val.length;i++){
          this.sendMultipleSelection.push(val[i].id);
          this.sendStatusList.push(val[i].sendStatus);
        }
      }else{
        this.sendMultipleSelection = [];
        this.sendStatusList=[];
      }
    },
    // 根据参会人类型查询参会人列表
    typeChange(val){
      this.pageperNum = 1;
      this.attTypeVal=val
      this.findParticipateUsersByCid();
    },
    sendTypeChange(val){
      this.pageperNum = 1;
      this.attTypeVal=val
      this.sendSmS();
    },
    // 根据推送状态查询参会人列表
    pushStatusChange(val){
      this.pageperNum = 1;
      this.pushStatus=val
      this.findParticipateUsersByCid();
    },
    sendStatusChange(val){
      this.pageperNum = 1;
      this.pushStatus=val
      this.sendSmS();
    },
    // 推送公告消息
    pushNoticeMessage(row){
      this.noticeId =row.id;
      this.findParticipateUsersByCid();
      this.attendRoleList();
      this.pushRevsuggdialogVisible = true;
    },
    // 短信发送
    sendSmSNoticeMessage(row){
      this.noticeId =row.id;
      this.sendSmS();
      this.attendRoleList();
      this.sendSmSRevsuggdialogVisible=true
    },
    // 参会人类型列表
    async attendRoleList() {
      let {data: res} = await this.$axios.attendRoleListAll();
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.participateTypeOptions = res.data;
      }
    },
    searchName(){
      this.pageperNum = 1;
      this.findParticipateUsersByCid();
    },
    sendSearchName(){
      this.pageperNum = 1;
      this.sendSmS();
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用,function(file, fileList)
    handleChange(file, fileList) {
      this.uploadFile(file, fileList);
    },
    async uploadFile(file, fileList) {
      if (file) {
        this.formdata = new FormData()
        this.formdata.append('file', file.raw)

        let res = await this.$axios.uploadNoticeFile(this.formdata);
        // console.log(res)
        if (res.data.code == 401) {
          this.$router.push("/login");
        } else if (res.data.code == 200) {
          console.log(res.data.data)
          this.fileList = fileList;
          // console.log(this.noticeFileList)
          this.noticeFileList.push(res.data.data);
          this.$message({
            message: '上传成功',
            type: "success",
          });
          this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      }
    },
    // 删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。function(file, fileList)
    handleRemove(file, fileList) {
      // console.log(file.name);
      this.fileList = fileList;
      var index = this.noticeFileList.findIndex(function (item) {
        var name1 = item;
        var houzui1 = name1.substring(name1.lastIndexOf('.'));
        var fileName1 = name1.substring(name1.indexOf('/',name1.indexOf('/')+1)+1,name1.lastIndexOf('-'));
        var fileName = fileName1+houzui1;
        console.log(fileName)
        return fileName == file.name;
      })
      // console.log(index)
      this.noticeFileList.splice(index, 1);
      // console.log(this.noticeFileList)
    },
    // 公告列表
    async noticePageList() {
      let data = _qs.stringify({
        page: this.pageNum, //页数
        size: this.pageSize, //每页显示条数
        type: this.noticeType,
        conferenceId: window.sessionStorage.getItem('conferenceId')
      });
      let {data: res} = await this.$axios.noticeManageList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    addModule() {
      // this.open = true;
      this.winName = '新增公告';
      this.revsuggdialogVisible = true;
    },
    // 修改公告
    updateNoticeManage(row) {
      this.revsuggdialogVisible = true;
      this.noticeId = row.id;
      // 创建编辑器
      // console.log(row.body)
      this.winName = '编辑公告信息';
      // 回显内容
      this.AddDateModel = JSON.parse(JSON.stringify(row));
      this.html = row.body;

      // this.fileList = row.files;
      if(row.files){
        if(row.files.includes("|")){
          this.noticeFileList = row.files.split("|");

          var list = new Array();
          list = row.files.split("|");

          for(var i = 0;i<list.length;i++){
            var name = list[i];
            var houzui = name.substring(name.lastIndexOf('.'));
            var fileName = name.substring(name.indexOf('/',name.indexOf('/')+1)+1,name.lastIndexOf('-'));
            this.fileList.push({"name":fileName+houzui,"url":Vue.prototype.GLOBAL + name})
          }
        }else{
          this.noticeFileList.push(row.files);
          // this.fileList = row.files;

          var name1 = row.files;
          var houzui1 = name1.substring(name1.lastIndexOf('.'));
          var fileName1 = name1.substring(name1.indexOf('/',name1.indexOf('/')+1)+1,name1.lastIndexOf('-'));
          this.fileList.push({"name":fileName1+houzui1,"url":Vue.prototype.GLOBAL + name1})
        }
      }
    },
    // 查找分会场下所有参会人员 推送
    async findParticipateUsersByCid() {
      let data = _qs.stringify({
        conferenceId: window.sessionStorage.getItem('conferenceId'),
        name:this.attenName,
        phone:this.attenPhone,
        participateType:this.attTypeVal,
        nId:this.pushStatus,
        page: this.pageperNum, //页数
        size: this.pageperSize, //每页显示条数
        noticeId:this.noticeId
      });
      // 查找分会场下所有参会人员
      let {data: res} = await this.$axios.findParticipateUsersByCid(data);
      // console.log(res)
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.pertableData = res.data.records;
        this.pertotal = res.data.total;
      } else if (res.code == 500) {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    // 发送短信    1111
    async sendSmS() {

      let data = _qs.stringify({
        conferenceId: window.sessionStorage.getItem('conferenceId'),//会议id
        name:this.attenName,
        phone:this.attenPhone,
        participateType:this.attTypeVal,
        sendStatus:this.sendStatus,
        page: this.pageperNum, //页数
        size: this.pageperSize,
        noticeId:this.noticeId
      });
      // console.log(data);
      let res = await this.$axios.sendSMSList(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        // console.log(res)
        this.sendTableData = res.data.data.records
        this.pertotal = res.data.data.total
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    handperleCurrentChange(val) {
      this.pageperNum = val;
      this.findParticipateUsersByCid();
    },
    sendCurrentChange(val) {
      this.pageperNum = val;
      this.sendSmS();
    },
    async pushNotice() {
      // 判断是否勾选了
      if(!this.multipleSelection || this.multipleSelection.length <= 0){
        return this.$message({
          message: '请勾选要发送的参会人！',
          type: "warning",
        });
      }


      // 判断是否有已发送的
      if(this.pushStatusList.includes('1')){
        return this.$message({
          message: '勾选的参会人中包含已推送的用户，请勿重复推送',
          type: "warning",
        });
      }


      let data = _qs.stringify({
        ids: this.multipleSelection.join(','),
        noticeId: this.noticeId,//菜单id
      });
      let res = await this.$axios.pushNotice(data);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        // 推送成功后刷新一下弹窗参会人页面
        this.attenName = '';
        this.attenPhone = '';
        this.attTypeVal = '';
        this.pushStatus = 0;
        this.pageperNum = 1;
        this.findParticipateUsersByCid();
        this.multipleSelection = [];
        this.pushStatusList = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },

    async sendNotice() {
      // 判断是否勾选了
      // console.log("id"+this.sendMultipleSelection)
      // console.log("状态"+this.sendStatusList)

      if(!this.sendMultipleSelection || this.sendMultipleSelection.length  <= 0 ){
        return this.$message({
          message: '请勾选要发送的参会人！',
          type: "warning",
        });
      }


      // 判断是否有已发送的
      if(this.sendStatusList.includes('1')){
        const confirmResult = await this.$confirm("本次勾选的人员中包含已发送过短信的！！！是否继续发送", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);

        if (confirmResult !== "confirm") {
          return this.$message.info("已经取消发送");
        }
      }


      let data = _qs.stringify({
        ids: this.sendMultipleSelection.join(','),
        noticeId: this.noticeId,
      });
      let res = await this.$axios.sendSMS(data);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 200) {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        // 推送成功后刷新一下弹窗参会人页面
        this.attenName = '';
        this.attenPhone = '';
        this.attTypeVal = '';
        this.sendStatus = 0;
        this.pageperNum = 1;
        this.sendSmS();
        this.sendMultipleSelection = [];
        this.sendStatusList = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: res.data.msg,
          type: "error",
        });
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      // console.log(`当前页: ${val}`);
      this.noticePageList();
    },
    revsuggdialogVisibleClose() {
      this.revsuggdialogVisible = false;
      this.noticeFileList = [];
      this.fileList = [];
      this.AddDateModel = {};
      this.editor.setHtml();
      this.html = '';
    },
    persondialogVisibleClose() {
      this.pushRevsuggdialogVisible = false
      this.pertableData = []
      this.$refs.multipleTable.clearSelection()
      this.attenName = '';
      this.attenPhone = '';
      this.attTypeVal = '';
      this.pushStatus = 0;
      this.pageperNum = 1;
      this.noticePageList()
    },
    sendPersondialogVisibleClose() {
      this.sendSmSRevsuggdialogVisible = false
      this.pertableData = []
      this.$refs.multipleTable.clearSelection();
      this.attenName = '';
      this.attenPhone = '';
      this.attTypeVal = '';
      this.sendStatus = 0;
      this.pageperNum = 1;
      this.noticePageList()
    },
    // 保存
    saveNoticeMessage() {
      this.addModuleClick = false;
      this.addModuleClickKing = true;

      if (this.winName == '编辑公告信息') {
        this.$refs.AddDateModel.validate(async (valid) => {
          if (valid) {
            let data = _qs.stringify({
              title: this.AddDateModel.title,
              describe: this.AddDateModel.describe,
              files: this.noticeFileList.join("|"),
              type: this.AddDateModel.type,
              body: this.html,
              id: this.noticeId
            });
            let {data: res} = await this.$axios.updateNoticeManage(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.noticePageList();
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.addModuleClick = true;
            this.addModuleClickKing = false;
          }
        })
      }
      // 新增
      else {
        this.$refs.AddDateModel.validate(async (valid) => {
          if (valid) {
            let data = _qs.stringify({
              title: this.AddDateModel.title,
              body: this.html,
              describe: this.AddDateModel.describe,
              files: this.noticeFileList.join("|"),
              type: this.AddDateModel.type,
              conferenceId: window.sessionStorage.getItem('conferenceId')
            });
            let {data: res} = await this.$axios.saveNoticeManage(data);
            // console.log(res)
            if (res.code == 401) {
              this.$router.push("/login");
            } else if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.revsuggdialogVisibleClose();
              this.pageNum = 1;
              this.noticePageList();
            } else {
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
            this.addModuleClick = true;
            this.addModuleClickKing = false;
          }
        })
      }
    },
    async deleteNoticeManage(row) {
      const confirmResult = await this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已经取消删除");
      }
      let data = _qs.stringify({
        id: row.id
      });
      let {data: res} = await this.$axios.deleteNoticeManage(data);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.pageNum = 1;
        this.noticePageList();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    statusChange(val) {
      this.pageNum = 1;
      // console.log(val)
      this.noticeType = val
      this.noticePageList();
    }
  },
  created() {
    this.noticePageList();
  }
  ,
  mounted() {
    // // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
  }
  ,
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
})
</script>

<style lang="less" scoped>
.el-pagination {
  text-align: center;
}

/deep/ .el-dialog__body {
  text-align: inherit;

  .btnCen {
    button {
      width: 150px;
    }
  }
}

.btnDiv {
  text-align: center;
  margin-top: 20px;

  button {
    width: 150px;
  }
}

.depTitle {
  display: flex;
  margin: 0 auto;
  margin-bottom: 20px;
}

.depart-addnum {
  width: 100%;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}

.el-pagination {
  margin-top: 20px;
}

.el-main {
  .table-title {
    display: flex;
    margin-bottom: 10px;

    .index-add {
      margin-left: 20px;
    }
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.text-ellipsis {
  width: 380px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.RightDiv {
  float: right;
  margin-bottom: 20px;
  display: flex;

  .firDiv {
    margin-left: 10px;
  }
}

.revSty {
  text-align: center;

  .el-button {
    width: 150px;
    margin-top: 20px;
  }
}

/deep/ .lognClass {
  width: 100%;
}

.formBotton {
  width: 100%;
  text-align: center;
  margin-left: -50px;
}

.widBomtn button {
  width: 200px
}
.RightDiv{
  float: right;
  margin-bottom: 20px;
  display: flex;
  .firDiv{
    margin-left: 10px;
  }
  .firDivF{
    margin-left: 10px;
    margin-right: 169px;
  }
}

///deep/ .conClass .el-form-item__content {
//  width: 76%;
//}
</style>
